import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { SET_CURRENT_EMP } from '../store/actions/types.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faEnvelope,
  faPhone,
  faUserMinus
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import I18n from '../../i18n';

const OurTeamList = ({ emp, auth }) => {
  const dispatch = useDispatch();

  const setCurrent = emp => {
    dispatch({
      type: SET_CURRENT_EMP,
      payload: emp
    });
  };

  const partnered = emp.partner === true ? <I18n t="partner" /> : null;

  const showSecondEmail =
    emp.emailTwo && emp.emailTwo.length > 1 ? (
      <Fragment>
        <FontAwesomeIcon icon={faEnvelope} />{' '}
        <a href={`mailto:${emp.emailTwo}`}>{emp.emailTwo}</a>
        <br />
      </Fragment>
    ) : null;

  const showLinkedin =
    emp.linkedin.length > 1 ? (
      <Fragment>
        <FontAwesomeIcon icon={faLinkedin} />{' '}
        <a href={emp.linkedin} target="_blank" rel="noopener noreferrer">
          {emp.linkedin}
        </a>
        <br />
      </Fragment>
    ) : null;

  const showSecondCV =
    emp.cvLink2.length > 1 ? (
      <a
        href={emp.cvLink2}
        className="btn our-team-a"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: '20px' }} />
        &nbsp;CV en
      </a>
    ) : null;

  const showDeleteButton = auth.uid ? (
    <button
      type="button"
      className="btn btn-danger"
      data-toggle="modal"
      data-target="#delete-user-modal"
      onClick={() => setCurrent(emp)}
      style={{ marginLeft: '30px' }}
    >
      <FontAwesomeIcon
        icon={faUserMinus}
        data-toggle="tooltip"
        data-placement="top"
        title="Delete user"
      />
    </button>
  ) : null;

  return (
    <div className="col col-lg-6 col-md-6">
      <div className="team-player">
        <img
          src={emp.imageUrl}
          alt="Team Profile"
          className="rounded-circle img-fluid img-raised"
          style={style}
        />

        <div className={emp.partner ? 'partner-align' : ''}>
          <h4 className="title">{emp.firstName.concat(` ${emp.lastName}`)}</h4>
          <p className="our-team-category">{partnered}</p>
          <p className="our-team-p">{emp.subtext}</p>
          <br />
        </div>
        <div>
          <p className="our-team-g text-center">
            <FontAwesomeIcon icon={faPhone} /> {emp.phone}
            <br />
            <FontAwesomeIcon icon={faEnvelope} />{' '}
            <a href={`mailto:${emp.emailOne}`}>{emp.emailOne}</a>
            <br />
            {showSecondEmail}
            {showLinkedin}
          </p>
        </div>
        <a
          href={emp.cvLink}
          className="btn our-team-a"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: '20px' }} />
          &nbsp;CV
        </a>
        {showSecondCV}
        {showDeleteButton}
      </div>
    </div>
  );
};

const style = {
  maxWidth: '280px'
};

export default OurTeamList;
