import firebase from '../../../config/firebaseConfig';

export const createProject = project => {
  return (dispatch, getState, getFirebase) => {
    return firebase.db
      .collection('projects')
      .add({
        ...project,
        authorFirstName: 'profile.firstName',
        authorLastName: 'profile.lastName',
        authorId: 'authorId',
        createdAt: new Date()
      })
      .then(() => {
        dispatch({ type: 'CREATE_PROJECT', project });
      })
      .catch(err => {
        dispatch({ type: 'CREATE_PROJECT_ERROR', err });
      });
  };
};

export const deleteProject = id => {
  return (dispatch, getState, getFirebase) => {
    return firebase.db
      .collection('projects')
      .doc(id)
      .delete()
      .then(() => {
        dispatch({ type: 'DELETE_PROJECT', id });
      })
      .catch(err => {
        dispatch({ type: 'DELETE_PROJECT_ERROR', err });
      });
  };
};
