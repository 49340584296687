import React from 'react';
import { Link } from 'react-scroll';
import LazyLoad from 'react-lazyload';
import I18n from '../../i18n';
import $ from 'jquery';

import Background2 from '../../assets/img/office/office-5-reworked.jpg';

import About from './About.js';
import OurTeam from '../ourTeam/OurTeam.js';
import Services from './Services.js';
import Certificates from '../certificates/Certificates.js';
import Transparency from '../transparency/Transparency.js';
import News from './News.js';
import Contact from './Contact.js';
import ScrollButton from '../ScrollButton.js';

const LandingPage = () => {
  const handleSubmit = () => {
    $('#servicesCarousel').carousel('pause');
  };

  return (
    <>
      <LazyLoad height={200} once>
        <div
          className="d-flex position-relative vh-100 cover hero"
          id="home"
          style={{
            backgroundImage: `url("${Background2}")`,
            maxHeight: '820px'
          }}
        >
          <div className="container-fluid">
            <div className="row d-sm-none d-md-flex d-flex mt-5">
              <div
                className="col col-sm-12 mt-5 text-center text-white quote-wrapper"
                style={{
                  backgroundColor: 'rgba(247, 110, 156, 0.7)'
                }}
              >
                <h6>
                  <I18n t="header-saying-1" />
                </h6>
                <hr />
                <h6 style={{ marginTop: '-10px' }}>
                  <I18n t="header-saying-2" />
                </h6>
              </div>{' '}
            </div>
            <div className="row d-none d-lg-flex justify-content-between quote-main">
              <div className="col col-3">
                <Link
                  to="services-scroll"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={500}
                >
                  <ul className="list-group landing-list ml-2">
                    <li className="list-group-item disabled">
                      <I18n t="serv" />
                    </li>

                    <li
                      className="list-group-item"
                      data-target="#servicesCarousel"
                      data-slide-to="0"
                      onClick={() => handleSubmit()}
                    >
                      <I18n t="serv rev" />
                    </li>
                    <li
                      className="list-group-item"
                      data-target="#servicesCarousel"
                      data-slide-to="1"
                      onClick={() => handleSubmit()}
                    >
                      <I18n t="accounting serv" />
                    </li>
                    <li
                      className="list-group-item"
                      data-target="#servicesCarousel"
                      data-slide-to="2"
                      onClick={() => handleSubmit()}
                    >
                      <I18n t="comp reg" />
                    </li>
                    <li
                      className="list-group-item"
                      data-target="#servicesCarousel"
                      data-slide-to="3"
                      onClick={() => handleSubmit()}
                    >
                      <I18n t="business plan title" />
                    </li>
                    <li
                      className="list-group-item"
                      data-target="#servicesCarousel"
                      data-slide-to="4"
                      onClick={() => handleSubmit()}
                    >
                      <I18n t="appr" />
                    </li>
                    <li
                      className="list-group-item"
                      data-target="#servicesCarousel"
                      data-slide-to="5"
                      onClick={() => handleSubmit()}
                    >
                      <I18n t="expertise" />
                    </li>
                    <li
                      className="list-group-item"
                      data-target="#servicesCarousel"
                      data-slide-to="6"
                      onClick={() => handleSubmit()}
                    >
                      <I18n t="inve coun" />
                    </li>
                    <li
                      className="list-group-item"
                      data-target="#servicesCarousel"
                      data-slide-to="7"
                      onClick={() => handleSubmit()}
                    >
                      <I18n t="brok advi" />
                    </li>
                  </ul>
                </Link>
              </div>
              <div className="col col-5 mr-auto">
                <h3 className="header-quote mr-5">
                  <I18n t="header-saying-1" />
                </h3>
                <h3 className="header-quote ml-5">
                  <I18n t="header-saying-2" />
                </h3>
              </div>
            </div>
          </div>
        </div>
        <About />
        <OurTeam />
        <Services />
        <Certificates />
        <Transparency />
        <News />
        <Contact />
        <ScrollButton scrollStepInPx="50" delayInMs="16.66" />
      </LazyLoad>
    </>
  );
};

export default LandingPage;
