import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import I18n from '../../i18n';

import AddEmployeeButton from '../buttons/AddEmployeeButton.js';
import OurTeamList from './OurTeamList.js';
import Loader from '../pages/Loader.js';
import { firestoreConnect } from 'react-redux-firebase';

const OurTeam = ({ employee, auth, location }) => {
  if (!employee) {
    return <Loader />;
  }
  const addEmployeeButton = auth.uid ? <AddEmployeeButton /> : null;

  const mkTeam = employee.map((emp, index) => (
    <OurTeamList emp={emp} auth={auth} key={index} />
  ));

  const enTeam = employee.map((emp, index) => (
    <OurTeamList emp={emp.en} auth={auth} key={index} />
  ));

  return (
    <div id="2" className="section section-team">
      <div id="our-team-scroll" className="wrapper text-center">
        <div className="container">
          <h1 className="h1-seo">
            <I18n t="our team title" />
          </h1>

          <div className="team">
            <div className="row">
              <div className="col col-12" style={{ marginBottom: '30px' }}>
                {addEmployeeButton}
              </div>
              {location.pathname === '/mk' ? mkTeam : enTeam}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    employee: state.firestore.ordered.employee,
    auth: state.firebase.auth
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'employee', orderBy: 'createdAt' }])
)(withRouter(OurTeam));
