import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { DELETE_EMP } from '../store/actions/types';
import firebase from '../../config/firebaseConfig';
import I18n from '../../i18n';

const DeleteUserModal = ({ emp }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    const id = emp.id;

    firebase.db
      .collection('employee')
      .doc(id)
      .delete()
      .then(() => {
        dispatch({ type: DELETE_EMP, payload: id });
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div
      className="modal fade"
      id="delete-user-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="delete-user-modal"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header" style={{ backgroundColor: 'pink' }}>
            <img
              src={require('../../assets/img/logo.png')}
              height="32"
              width="32"
              alt="logo"
              style={{ marginRight: '20px' }}
            />
            <h5 className="modal-title" id="delete-user-modal">
              <I18n t="delete_user_title" />
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              {emp && (
                <h6>
                  <I18n t="delete_user_1" />
                  {emp.firstName} {emp.lastName}
                  <I18n t="delete_user_2" />
                </h6>
              )}
            </div>
          </div>
          <div className="modal-footer" style={{ backgroundColor: 'pink' }}>
            <button
              type="button"
              className="btn btn-warning"
              data-dismiss="modal"
            >
              <I18n t="no" />
            </button>
            <button
              type="button"
              className="btn btn-pink"
              onClick={handleClick}
            >
              <I18n t="yes" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  emp: state.emp.current
});

export default connect(mapStateToProps)(DeleteUserModal);
