import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import Form from './Form';
import I18n from '../../i18n';

const Contact = props => {
  return (
    <div id="7" className="section section-contact-us">
      <div id="contact-scroll" className="container text-center">
        <h2 className="title">
          <I18n t="cont 1" />
        </h2>
        <p className="description">
          <I18n t="cont 2" />
        </p>

        <div className="row">
          <div className="col col-lg-6 col-md-8 ml-auto mr-auto">
            <Form />
          </div>
        </div>
        <br></br>
        <h2 className="h1-seo">
          <I18n t="loc 1" />
        </h2>
        <div className="row justify-content-center">
          <div className="col col-sm-12 contact-map">
            <Map
              google={props.google}
              zoom={16}
              initialCenter={{ lat: 41.63782, lng: 22.461255 }}
            >
              <Marker position={{ lat: 41.63782, lng: 22.461255 }} />
            </Map>
          </div>
        </div>
        <br></br>
        <br></br>
        <h6>+389 32 633 632</h6>
        <h6>+389 72 208 072</h6>
        <h6>
          <I18n t="address" />
        </h6>
        <h6>idejaplusrevizija@yahoo.com</h6>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAQgySr0V2gp8fw8GJ6gCEPnT_k0qypat8'
})(Contact);
