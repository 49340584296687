import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import './i18n';
import * as serviceWorker from './serviceWorker';

import firebase from 'firebase/app';
// import auth from './config/firebaseConfig';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore'; // make sure you add this for firestore

import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import configureStore from './components/store/configureStore.js';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';

import './fonts/Montserrat-Regular.ttf';

const initialState = window && window.__INITIAL_STATE__; // set initial state here
const store = configureStore(initialState);

const rrfConfig = {
  useFirestoreForProfile: true,
  userProfile: 'users',
  attachAuthIsReady: true,
  firebaseStateName: 'firebase'
};

// listen for auth state changes
// auth.auth.onAuthStateChanged(user => {
//   if (user) {
//     console.log('user logged in: ', user);
//   } else {
//     console.log('user logged out');
//   }
// });

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider
      firebase={firebase}
      config={rrfConfig}
      dispatch={store.dispatch}
      createFirestoreInstance={createFirestoreInstance}
    >
      <App store={store} />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
