import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { MK_TRANS } from '../store/actions/types.js';
import firebase from '../../config/firebaseConfig.js';

const AddTransModal = () => {
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = e => {
    e.preventDefault();
    let timestamp = firebase.firebase.firestore.Timestamp.fromDate(new Date());

    const newTrans = {
      url,
      title,
      createdAt: timestamp
    };

    dispatch({
      type: MK_TRANS,
      payload: newTrans
    });
  };

  const handleChange = e => {
    if (e.target.files[0]) {
      const doc = e.target.files[0];
      setFile(() => doc);
    }
  };

  const handleUpload = () => {
    const doc = file;
    const uploadTask = firebase.firebase
      .storage()
      .ref(`transparency/${doc.name}`)
      .put(doc);
    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      error => {
        console.log(error);
      },
      () => {
        firebase.firebase
          .storage()
          .ref('transparency')
          .child(doc.name)
          .getDownloadURL()
          .then(url => {
            setUrl(url);
          });
      }
    );
  };

  return (
    <div className="container">
      <div
        id="add-trans-modal"
        className="modal fade"
        role="dialog"
        aria-labelledby="addTransModal"
        aria-hidden="true"
        tabIndex="-1"
        style={{ fontFamily: 'Montserrat' }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: 'pink' }}>
              <img
                src={require('../../assets/img/logo.png')}
                height="32"
                width="32"
                alt="logo"
                style={{ marginRight: '20px' }}
              />
              <h5 className="modal-title" id="addTransModal">
                Додади Извештај
              </h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <form onSubmit={handleSubmit}>
                <div className="container-fluid">
                  <div className="form-group">
                    <label
                      htmlFor="titleENTR"
                      style={{ fontSize: '1.1rem', fontWeight: '500' }}
                    >
                      Наслов
                    </label>
                    <input
                      className="form-control"
                      id="titleENTR"
                      type="text"
                      name="add-trans-title"
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                    />
                  </div>

                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="addTransparencyReport"
                        onChange={handleChange}
                        required
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="addTransparencyReport"
                      >
                        Одберете документ..
                      </label>
                    </div>
                    <div
                      className="input-group-append"
                      style={{ marginTop: '-10px' }}
                    >
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleUpload}
                      >
                        Прикачи
                      </button>
                    </div>
                  </div>
                  <div className="progress" style={{ height: '20px' }}>
                    <div
                      className="progress-bar-striped bg-pink text-center"
                      role="progressbar"
                      style={{ width: progress && '100%' }}
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {progress > 0 ? `${progress}% прикачено` : null}
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary btn-md btn-block"
                  >
                    Потврди
                  </button>

                  <div
                    className="modal-footer"
                    style={{ backgroundColor: 'pink' }}
                  >
                    <button
                      type="button"
                      className="btn btn-pink"
                      data-toggle="modal"
                      data-target="#add-trans-en-modal"
                    >
                      Продолжи (Англиски)
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  trans: state.transparency.trans
});

export default connect(mapStateToProps)(AddTransModal);
