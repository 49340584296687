import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import firebase from '../../config/firebaseConfig.js';
import I18n from '../../i18n';

import TransparencyList from './TransparencyList.js';
import AddTransButton from '../buttons/AddTransButton.js';
import Loader from '../pages/Loader.js';

const Transparency = ({ trans, auth, location }) => {
  const dispatch = useDispatch();
  const addTransButton = auth.uid ? <AddTransButton /> : null;

  const handleDelete = trans => {
    const id = trans.id;

    firebase.db
      .collection('transparency')
      .doc(id)
      .delete()
      .then(() => {
        dispatch({ type: 'DELETE_TRANS', payload: id });
      })
      .catch(err => {
        dispatch({ type: 'DELETE_TRANS_ERROR', payload: err.message });
      });
  };

  if (!trans) {
    return <Loader />;
  }

  const mkTrans = trans.map(tran => (
    <li key={tran.id}>
      <TransparencyList trans={tran} auth={auth} onDelete={handleDelete} />
    </li>
  ));

  const enTrans = trans.map(tran => (
    <li key={tran.id}>
      <TransparencyList trans={tran.en} auth={auth} onDelete={handleDelete} />
    </li>
  ));

  return (
    <div id="5" className="section section-transparency">
      <div id="transparency-scroll" className="container text-center">
        <h1 className="h1-seo">
          <I18n t="transparency" />
        </h1>
        <div className="row">
          {addTransButton}
          <div className="col col-md-12">
            <ul className="transparency-ul">
              {location.pathname === '/mk' ? mkTrans : enTrans}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    trans: state.firestore.ordered.transparency,
    auth: state.firebase.auth
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'transparency', orderBy: ['createdAt', 'desc'] }
  ])
)(withRouter(Transparency));
