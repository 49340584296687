import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { SET_EMP_ID } from '../store/actions/types.js';
import firebase from '../../config/firebaseConfig.js';

const AddEmployeeEnModal = ({ emp }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [subtext, setSubtext] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = e => {
    const docRef = firebase.db.collection('employee').doc();

    const newEmployee = {
      ...emp.mkEmp,
      en: {
        firstName,
        lastName,
        subtext,
        partner: emp.mkEmp.partner,
        phone: emp.mkEmp.phone,
        emailOne: emp.mkEmp.emailOne,
        emailTwo: emp.mkEmp.emailTwo,
        linkedin: emp.mkEmp.linkedin,
        imageUrl: emp.mkEmp.imageUrl,
        cvLink: emp.mkEmp.cvLink,
        cvLink2: emp.mkEmp.cvLink2,
        createdAt: emp.mkEmp.createdAt,
        id: docRef.id
      }
    };

    docRef
      .set({ ...newEmployee })
      .then(emp => {
        dispatch({
          type: SET_EMP_ID,
          payload: emp.id
        });
      })
      .catch(err => {
        dispatch({ type: 'CREATE_EMPLOYEE_ERROR', payload: err.message });
      });
  };

  return (
    <div className="container">
      <div
        id="add-employee-en-modal"
        className="modal fade"
        role="dialog"
        aria-labelledby="addEmployeeEnModal"
        aria-hidden="true"
        tabIndex="-1"
        style={{ fontFamily: 'Montserrat' }}
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ minWidth: '920px' }}
        >
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: 'pink' }}>
              <img
                src={require('../../assets/img/logo.png')}
                height="32"
                width="32"
                alt="logo"
                style={{ marginRight: '20px' }}
              />
              <h5 className="modal-title" id="addEmployeeEnModal">
                Add an Employee
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center" style={{ height: '780px' }}>
              <form onSubmit={handleSubmit}>
                <div className="container-fluid">
                  <h4>English Version</h4>
                  <br />
                  <div className="row">
                    {/* First Name */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="firstNameEN"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          First Name
                        </label>
                        <input
                          className="form-control"
                          id="firstNameEN"
                          type="text"
                          name="add-employee-en-firstName"
                          value={firstName}
                          onChange={e => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Last Name */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="lastNameEN"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Last Name
                        </label>
                        <input
                          className="form-control"
                          id="lastNameEN"
                          type="text"
                          name="add-employee-en-lastName"
                          value={lastName}
                          onChange={e => setLastName(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Subtext */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="subtextEN"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Subtext
                        </label>
                        <input
                          className="form-control"
                          id="subtextEN"
                          type="text"
                          name="add-employee-en-subtext"
                          value={subtext}
                          onChange={e => setSubtext(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Phone */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="phoneEN"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Phone
                        </label>
                        <input
                          className="form-control"
                          id="phoneEN"
                          type="text"
                          name="add-employee-en-phone"
                          value={emp.mkEmp.phone || ''}
                          disabled
                        />
                      </div>
                    </div>

                    {/* Email 1 */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="emailOneEN"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Email 1
                        </label>
                        <input
                          className="form-control"
                          id="emailOneEN"
                          type="text"
                          name="add-employee-en-emailOne"
                          value={emp.mkEmp.emailOne || ''}
                          disabled
                        />
                      </div>
                    </div>

                    {/* Email 2 */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="emailTwoEN"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Email 2 (Optional)
                        </label>
                        <input
                          className="form-control"
                          id="emailTwoEN"
                          type="text"
                          name="add-employee-en-emailTwo"
                          value={emp.mkEmp.emailTwo || ''}
                          disabled
                        />
                      </div>
                    </div>

                    {/* Linkedin */}
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="linkedinEN"
                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                        >
                          Linkedin
                        </label>
                        <input
                          className="form-control"
                          id="linkedinEN"
                          type="text"
                          name="add-employee-en-linkedin"
                          value={emp.mkEmp.linkedin || ''}
                          disabled
                        />
                      </div>
                    </div>

                    {/* Partner */}
                    <div className="col-4 mt-5 ml-2">
                      <div className="input-group">
                        <div className="form-group form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="partnerCheckEN"
                            value={emp.mkEmp.partner || ''}
                            disabled
                          />
                          <label
                            className="form-check-label"
                            htmlFor="partnerCheckEN"
                          >
                            Ideja Plus Partner
                          </label>
                        </div>
                      </div>
                    </div>

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <div
                      className="modal-footer col-12"
                      style={{ backgroundColor: 'pink' }}
                    >
                      <button
                        type="button"
                        className="btn btn-warning"
                        data-dismiss="modal"
                        style={{ color: 'white' }}
                      >
                        Back
                      </button>

                      <button type="submit" className="btn btn-pink">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  emp: state.emp
});

export default connect(mapStateToProps)(AddEmployeeEnModal);
